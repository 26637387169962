import { motion } from 'framer-motion';
import { MobileNavItem } from './MobileNavItems';
import classNames from 'classnames';
import { Link } from 'react-scroll';

const hoverClass =
  'bg-white rounded-2xl px-4 py-1 flex  mx-auto w-fit  items-center justify-center';
const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const Items = [
  {
    id: '1',
    element: (
      <Link
        className={hoverClass}
        activeClass='active'
        to='project-section'
        spy={true}
        smooth={true}
        offset={0}
        duration={100}
      >
        Projects
      </Link>
    ),
  },
  {
    id: '2',
    element: (
      <Link
        className={hoverClass}
        activeClass='active'
        to='faq-section'
        spy={true}
        smooth={true}
        offset={0}
        duration={100}
      >
        FAQs
      </Link>
    ),
  },
];

export const MobileNavbar = ({ isOpen }) => (
  <>
    <motion.ul
      variants={variants}
      className={classNames(
        'text-center mt-4  py-8 relative z-10 w-1/2 mx-auto ',
        {
          'pointer-events-none h-0': !isOpen,
        },
        {
          'h-auto': isOpen,
        }
      )}
    >
      {Items.map((item) => (
        <MobileNavItem key={item.id} element={item.element} />
      ))}
    </motion.ul>
  </>
);
