import React from 'react';
import Card from './Card';

const ProjectCards = [
  {
    heading: 'Outreach & Community Associate (Food and Beverage)',
    domainExpertise: 'Marketing',
    location: 'Gurgaon',
    totalTime: 'Full Time',
    remuneration: 'Not Disclosed',
    companyLogo:
      'https://www.goodlives.in/wp-content/uploads/2020/08/cropped-GOOD_LIVES-removebg-preview-removebg-preview-1.png',
    customButton: (
      <a
        className='px-6 py-2 rounded-md bg-primary-lighter font-bold my-4'
        href='https://drive.google.com/file/d/1J-_uVy67tos4n-Ltx6YYO7W9DK21m-HO/view'
        target='_blank'
        rel='noreferrer'
      >
        Download PDF
      </a>
    ),
    skills: [
      'Analytical Thinking',
      'Research',
      'Human Resource Management',
      'Communication',
      'Presentation',
    ],

    details:
      'As an Outreach & Community Associate specializing in the food and beverage sector, you will be responsible for managing and engaging with food and beverage companies and other stakeholders within the community. You will play a crucial role in building and nurturing relationships, driving collaborations, and promoting the growth of our community while aligning with our mission and values.',
    prerequisites: ['Basic research skills and knowledge of human Resources'],
    objectives: [
      'Develop a good understanding of the company’s background, industry and market',
      'Build knowledge of Human Resource Management Strategy',
      'Build effective communication and presentation skills',
    ],
    businessObjective:
      'To propose a solution to streamline the process of employer onboarding and its related HR policies for DoT Glasses',
  },
  {
    heading: 'GoodLives Extension on Microsoft Teams',
    domainExpertise: 'Full- Stack Development',
    location: 'Remote',
    totalTime: 'Project Basis',
    remuneration: 'Rs.15,000',
    companyLogo:
      'https://www.goodlives.in/wp-content/uploads/2020/08/cropped-GOOD_LIVES-removebg-preview-removebg-preview-1.png',
    customButton: (
      <a
        className='px-6 py-2 rounded-md bg-primary-lighter font-bold my-4'
        href='https://drive.google.com/file/d/1Jie_a0r7cZlfJTYxpYU91q4f7q1H9kHd/view'
        target='_blank'
        rel='noreferrer'
      >
        Download PDF
      </a>
    ),
    skills: [
      'Analytical Thinking',
      'Research',
      'Problem-Solving ',
      'Communication & interpersonal skills',
    ],
    industry: 'Information Technology and Services',
    details:
      'We are a bootstrap startup trying to use tech to create more awareness about mental health issues and provide online counsellors/psychologists to people having trouble dealing with life (depression, anxiety, etc.) GoodLives aims at making mental health treatment more accessible. You can check more details at goodlives. in.',
    prerequisites: [
      'Basic research skills and knowledge of Business development',
    ],
    objectives: [
      'To understand the basics of Business Development and create strategies',
      'To know how to generate leads',
      'To know how to do in-depth research and analyze data to understand the market, product and the customers',
    ],
    businessObjective:
      'Business development strategy to increase market reach and business growth for SPEAK',
  },
];

const Projects = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-12 lg:w-3/4 mx-auto'>
      {/* project box */}
      {ProjectCards.map((card, index) => {
        return (
          <Card
            key={index}
            heading={card.heading}
            domainExpertise={card.domainExpertise}
            location={card.location}
            totalTime={card.totalTime}
            customButton={card.customButton}
            skills={card.skills}
            level={card.level}
            details={card.details}
            prerequisites={card.prerequisites}
            objectives={card.objectives}
            businessObjective={card.businessObjective}
            companyLogo={card.companyLogo}
            remuneration={card.remuneration}
          />
        );
      })}
    </div>
  );
};

export default Projects;
