import './App.css';
import Header from './components/Header';
import Home from './components/home';
import Information from './components/home/Information';
import CompanyProjects from './components/company-project';
import Faq from './components/faqSection/Faq';
import Footer from './components/Footer';

function App() {
  return (
    <div className='px-4 md:px-8 lg:px-16 space-y-12 lg:space-y-24 mb-6 font-manrope overflow-x-hidden'>
      <Header />
      <Home />
      <Information />
      <CompanyProjects />
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
