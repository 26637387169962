export const FAQ = [
  {
    id: 1,
    name: 'About the Program',
    questions: [
      {
        question: 'What kind of projects are available?',
        content: [
          {
            type: 'text',
            content:
              'We offer a diverse range of projects across different domains. You will find projects in technology, marketing, design, and more.',
          },
        ],
      },
      {
        question: 'What rewards can I earn?',
        content: [
          {
            type: 'text',
            content:
              'Rewards can vary, but you may receive monetary compensation, paid internships, or other incentives based on the project outcome and the employer. ',
          },
        ],
      },
      {
        question: 'Is there a fee to participate? ',
        content: [
          {
            type: 'text',
            content: 'No, it is free to express interest and work on projects.',
          },
        ],
      },

      // {
      //   question:
      //     'How will the program help in building a robust profile for college applications?',
      //   content: [
      //     {
      //       type: 'text',
      //       content:
      //         'The Global Summer Apprenticeship Program is designed to help participants build a robust profile for college applications in multiple ways. ',
      //     },
      //     {
      //       type: 'list',
      //       content: [
      //         'The program offers practical experience, which can enhance their resumes and make them stand out from other applicants. ',
      //         'The program provides global exposure through virtual travel, which can broaden their perspectives and add a unique aspect to their applications. ',
      //         'The program offers industry certifications and covers entrepreneurial concepts, which can showcase their skills and knowledge.',
      //       ],
      //     },
      //     {
      //       type: 'text',
      //       content:
      //         'Finally, the program fosters an entrepreneurial mindset and offers opportunities for peer-based learning and networking, which can demonstrate their leadership and teamwork abilities to college admissions committees.',
      //     },
      //   ],
      // },
    ],
  },
];
