import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 7,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 3,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 5,
  },
};

const employersLogos = [
  {
    id: 1,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153103/landing/company-logo/Group_317_dfnt7z.png', // dot
  },
  {
    id: 2,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_330_c2vgq5.png', //dhl
  },
  {
    id: 3,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1661153101/landing/company-logo/Group_336_elsimv.png', //idea
  },
  {
    id: 4,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1675862698/uploaded_resources/1662529045900-removebg-preview_su7ynq.png',
  },
  {
    id: 5,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1642159267/uploaded_resources/TBI-English.svg_ozlrxe.png',
  },
  {
    id: 6,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1620742231/uploaded_resources/memomoti-logo_rhtbqo.png',
  },
  {
    id: 7,
    logo: 'https://res.cloudinary.com/belong/image/upload/v1617959923/uploaded_resources/uGlobally_ncchzv.png',
  },
];
const Slider = ({ deviceType }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows={false}
      autoPlay
      autoPlaySpeed={1000}
      centerMode={false}
      className=''
      containerClass='container-with-dots'
      customTransition='all 4s linear'
      transitionDuration={1000}
      dotListClass=''
      draggable
      focusOnSelect={false}
      infinite
      itemClass=''
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=''
      slidesToSlide={1}
      swipeable
    >
      {employersLogos.map(({ id, logo }) => {
        return (
          <div key={id} className='flex  justify-center mt-8 items-center '>
            <img src={logo} alt='company-logo' className='h-12' />
          </div>
        );
      })}
    </Carousel>
  );
};

export default Slider;
