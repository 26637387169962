import React from 'react';
import Slider from './Slider';
import { Element } from 'react-scroll';

const Index = () => {
  return (
    <Element name='about'>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
        <div>
          <div className='text-3xl lg:text-4xl font-bold	 space-y-4 '>
            <p>
              Solve Real-World <span className='text-primary'>Projects</span>{' '}
            </p>
            <p>
              Build Your<span className='text-primary'>Skills</span> Portfolio
            </p>
            <p>
              <span className='text-primary'>Earn</span> Certifications &
              Rewards
            </p>
          </div>
          <div className='mt-5 text-gray-700 font-medium text-xl'>
            Transform Your Skills into Real-World <br></br> Impact and Unlock
            Opportunities
          </div>
        </div>
        <div>
          <img
            className='sm:-mt-12 mx-auto'
            src='https://res.cloudinary.com/belong/image/upload/v1699616690/uploaded_resources/previewImage_pbzass.png'
            alt='pre'
          />
        </div>
      </div>
      <Slider />
    </Element>
  );
};

export default Index;
