const FaqContent = ({ content }) => {
  return (
    <div>
      {content.type === 'text' ? (
        <p>{content.content}</p>
      ) : (
        <ul className='list-decimal ml-4 space-y-2'>
          {content.content.map((listItem, index) => (
            <li key={index}>{listItem}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FaqContent;
