import React from 'react';
// import ImageCard from './ImageCard';
const InformationPoints = () => {
  return (
    <div>
      <div className='grid grid-cols-2'>
        <div className='flex flex-col lg:flex-row gap-4 items-center'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>01</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Explore Projects</p>
            <p className='text-gray-600 font-medium text-lg'>
              Browse through a list of exciting projects from top companies
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-4 items-center my-6'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>02</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Show Interest</p>
            <p className='text-gray-600 font-medium text-lg'>
              Select projects that align with your skills and fill out the
              interest form
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-4 items-center my-6'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>03</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Selection & Commencement</p>
            <p className='text-gray-600 font-medium text-lg'>
              The selected candidates are onboarded email/phone
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-4 items-center my-6'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>04</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Solve Challenges</p>
            <p className='text-gray-600 font-medium text-lg'>
              Work on project tasks and demonstrate your abilities
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-4 items-center my-6'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>05</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Project Feedback</p>
            <p className='text-gray-600 font-medium text-lg'>
              Get feedback on your work from the company
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-4 items-center my-6'>
          <div className='text-primary border border-primary rounded-full p-2 h-12 w-12 text-xl lg:text-2xl font-semibold text-center'>
            <p>06</p>
          </div>
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Access Rewards</p>
            <p className='text-gray-600 font-medium text-lg'>
              Based on the project outcomes earn rewards and certification
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationPoints;
