import { Link } from 'react-scroll';

const hoverClass =
  'hover:text-primary  hover:cursor-pointer hover:font-semibold  transition-all ease-in-out duration-300';
const Navbar = () => {
  return (
    <div className='flex  flex-col space-x-14 lg:flex-row lg:items-center text-[#505050]'>
      <Link
        className={hoverClass}
        activeClass='active'
        to='about'
        spy={true}
        smooth={true}
        offset={-100}
        duration={100}
      >
        About
      </Link>
      <Link
        className={hoverClass}
        activeClass='active'
        to='benefits'
        spy={true}
        smooth={true}
        offset={-100}
        duration={100}
      >
        Benefits
      </Link>
      <Link
        className={hoverClass}
        activeClass='active'
        to='project-section'
        spy={true}
        smooth={true}
        offset={-100}
        duration={100}
      >
        Projects
      </Link>{' '}
      <Link
        className={hoverClass}
        activeClass='active'
        to='faq-section'
        spy={true}
        smooth={true}
        offset={-100}
        duration={100}
      >
        FAQs
      </Link>{' '}
    </div>
  );
};

export default Navbar;
