import React from 'react';
import Projects from './Projects';
import { Element } from 'react-scroll';

const Index = () => {
  return (
    <>
      <Element name='project-section' className='space-y-6 lg:space-y-14'>
        <div className='text-center'>
          <p className='text-xl lg:text-2xl my-2 font-bold '>
            Company <span className='text-primary'>Projects</span>
          </p>
          <p className='mt-5 text-gray-600 font-medium text-lg'>
            You'll find exciting projects from innovative companies, waiting for
            you to make your mark
          </p>
        </div>
        <Projects />
      </Element>
    </>
  );
};

export default Index;
