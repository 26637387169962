import React from 'react';
import InformationPoints from './InformationPoints';
import BenefitPoints from './BenefitPoints';
import { Element } from 'react-scroll';

const Information = () => {
  return (
    <div className='space-y-6 lg:space-y-14'>
      <div className='text-center'>
        <p className='text-xl lg:text-2xl my-2 font-bold '>How Does It Work?</p>
        <p className='mt-5 text-gray-600 font-medium text-lg'>
          This platform enables you to gain hands-on experience, showcase your
          abilities, and unlock exciting opportunities
        </p>
      </div>
      <InformationPoints />
      <Element name='benefits'>
        <p className='text-xl lg:text-2xl  my-2 font-bold text-center'>
          How Does This Experience <span className='text-primary'>Benefit</span>{' '}
          You?
        </p>
      </Element>

      <BenefitPoints />
    </div>
  );
};

export default Information;
