import FaqTypes from './FaqTypes';
import { FAQ } from './allFaq';
import { Element } from 'react-scroll';

const Faq = () => {
  return (
    <>
      <Element
        name='faq-section'
        className='grid grid-cols-12 place-content-center mt-24 lg:mt-32 bg-gray-50 -mx-4 p-4 md:p-0 md:bg-white'
      >
        <div className='col-span-12 '>
          <h1 className='text-textColor-main lg:font-extrabold font-medium lg:text-4xl text-3xl text-center'>
            Frequently Asked <span className='text-primary'> Questions</span>
          </h1>
        </div>
        <div className='col-span-12 px-auto lg:mt-10 mt-6 '>
          <div className='lg:w-3/4 mx-auto w-full'>
            {FAQ.map((category) => {
              return (
                <div key={category.id}>
                  {/* <p className='font-bold text-primary text-xl ml-4'>
                    {category.name}
                  </p> */}
                  {category.questions.map((question) => {
                    return (
                      <FaqTypes
                        key={question.question}
                        question={question.question}
                        content={question.content}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </Element>
    </>
  );
};

export default Faq;
