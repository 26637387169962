import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
// import 'reactjs-popup/dist/index.css';
// const settings = 'images/domains/setting.svg';
// const time = 'images/domains/time.svg';
// const popupSettings = 'images/domains/popup/popupSetting.svg';
// const popupTime = 'images/domains/popup/popupTime.svg';
// const popupLevel = 'images/domains/popup/popupLevel.svg';
// const popupCountry = 'images/domains/popup/popupCountry.svg';
// const popupLinkedIn = 'images/domains/popup/popupLinkedin.svg';

const Card = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCardSelected, setIsCardSelected] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className='group'>
      <div
        onClick={() => setIsCardSelected(true)}
        className={classNames(
          'border group-hover:shadow-[12px_12px_0px_rgba(250,200,72,1)] transition ease-in-out duration-300 cursor-pointer min-h-fit   w-80 lg:w-96   mx-auto flex  flex-col justify-between space-y-4 items-start  px-3 py-5 ',
          {
            'border-2 border-graphYellow shadow-[12px_12px_0px_rgba(250,200,72,1)]':
              isCardSelected,
            'border-black': !isCardSelected,
          }
        )}
      >
        <p className='font-bold text-2xl'>{props.heading}</p>
        <div
          className={classNames(
            'h-0.5 w-full group-hover:bg-graphYellow transition ease-in-out duration-300',
            {
              'bg-graphYellow': isCardSelected,
              'bg-[#B0B0B0]': !isCardSelected,
            }
          )}
        ></div>
        {/* company logo and flag */}
        <div className='flex space-x-4'>
          <img
            src={props.companyLogo}
            alt='company-logo'
            className='h-8 object-contain'
          />
          <div className='border-r-2 h-8'></div>
          {/* <img
            src={props.countryFlag}
            alt='flag'
            className='h-8 object-contain'
          /> */}
        </div>
        {/* domain name */}
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-1'>
          <p> Domain Expertise</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.domainExpertise}
          </p>
        </div>
        {/* industry and time */}
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-1'>
          <p> Employment Type</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.totalTime}
          </p>
        </div>
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-1'>
          <p> Location</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.location}
          </p>
        </div>
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-1'>
          <p> Remuneration</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.remuneration}
          </p>
        </div>

        <button
          onClick={openModal}
          className={classNames(
            'w-full border border-black text-[#505050] text-xs lg:text-base font-medium lg:font-bold p-1 lg:p-2   group-hover:bg-primary-lighter group-hover:text-black group-hover:shadow-[8px_8px_0px_rgba(0,0,0,1)]  transition ease-in-out duration-300',
            {
              'bg-primary text-black shadow-[8px_8px_0px_rgba(0,0,0,1)]':
                isCardSelected,
            }
          )}
        >
          View Project
        </button>

        <a
          href='https://forms.gle/nNVQupR69ofdA6rF6'
          target='_blank'
          rel='noreferrer'
          className={classNames(
            'w-full border border-black text-[#505050] text-xs lg:text-base font-medium lg:font-bold p-1 lg:p-2 text-center  group-hover:bg-primary-lighter group-hover:text-black group-hover:shadow-[8px_8px_0px_rgba(0,0,0,1)]  transition ease-in-out duration-300',
            {
              'bg-primary text-black shadow-[8px_8px_0px_rgba(0,0,0,1)]':
                isCardSelected,
            }
          )}
        >
          Register
        </a>
      </div>

      {/* <div
        onClick={() => setIsCardSelected(true)}
        className={classNames(
          'border group-hover:shadow-[12px_12px_0px_rgba(16,171,183,0.5)] transition ease-in-out duration-300 cursor-pointer min-h-fit lg:h-96  w-80 lg:w-96   mx-auto flex  flex-col justify-between space-y-4 items-start  px-3 py-5 ',
          {
            'border-2 border-primary shadow-[12px_12px_0px_rgba(16,171,183,0.5)]':
              isCardSelected,
            'border-black': !isCardSelected,
          }
        )}
      >
        <p className='font-bold text-2xl'>{props.heading}</p>
        <div
          className={classNames(
            'h-0.5 w-full group-hover:bg-primary transition ease-in-out duration-300',
            {
              'bg-primary': isCardSelected,
              'bg-[#B0B0B0]': !isCardSelected,
            }
          )}
        ></div>

      
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-4'>
          <p> Employment Type</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.totalTime}
          </p>
        </div>
       
        <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center space-x-3 py-4'>
          <p> Location</p>
          <p className='bg-[#FFF7CD] rounded-2xl px-3 py-0.5'>
            {props.location}
          </p>
        </div>
     
        <p> {props.domainExpertise}</p>

        
        <button
          onClick={openModal}
          className={classNames(
            'w-full border border-black text-[#505050] text-xs lg:text-base font-medium lg:font-bold p-1 lg:p-2   group-hover:bg-primary-lighter group-hover:text-black group-hover:shadow-[8px_8px_0px_rgba(0,0,0,1)]  transition ease-in-out duration-300',
            {
              'bg-primary text-black shadow-[8px_8px_0px_rgba(0,0,0,1)]':
                isCardSelected,
            }
          )}
        >
          View Project
        </button>
      </div> */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <div className='overflow-hidden rounded-lg shadow-[0px_8px_15px_rgba(158,158,158,0.2)] ring-1 ring-black ring-opacity-5'>
                    <div className='relative grid  bg-white'>
                      <div className='bg-primary text-white font-semibold flex justify-between items-center px-6 py-3 '>
                        <p>Overview</p> 
                        <p
                          onClick={closeModal}
                          className='cursor-pointer font-bold text-white'
                        >
                          X
                        </p>
                      </div>
                      {/* All content in white background */}
                      <div className='px-6 py-4'>
                        {/* company logo, heading and Linkedin Icon */}
                        <div className=' lg:pt-2 lg:pb-4'>
                          <div className='text-[#505050] font-semibold text-lg lg:text-2xl'>
                            {props.heading}
                          </div>
                        </div>

                        {/* text sections (total-3)*/}
                        {/* section-1 */}
                        <div className='space-y-2 mt-4'>
                          <div className='border border-black px-2 py-1 w-fit shadow-[4px_4px_0px_rgba(16,171,183,1)]'>
                            <p className=' font-semibold text-lg'>About</p>
                          </div>
                          <div className='border-t-2 border-primary w-12'></div>
                          <p className='text-[#767676] text-sm'>
                            {props.details}
                          </p>
                        </div>
                        {/* section-2 */}
                        {/* <div className='space-y-2 mt-4'>
                          <div className='border border-black px-2 py-1 w-fit shadow-[4px_4px_0px_rgba(250,200,72,1)]'>
                            <p className=' font-semibold text-lg'>
                              Prerequisites
                            </p>
                          </div>
                          <div className='border-t-2 border-primary w-12'></div>
                          <div className='space-y-2'>
                            {props.prerequisites.map((prerequisite, index) => {
                              return (
                                <p
                                  key={index}
                                  className='text-[#767676] text-sm'
                                >
                                  {prerequisite}
                                </p>
                              );
                            })}
                          </div>
                        </div> */}
                        {/* section-3 */}
                        {/* <div className='lg:flex gap-4 lg:gap-12 my-4'>
                          <div className='space-y-2 mt-4 lg:basis-[40%]'>
                            <div className='border border-black px-2 py-1 w-fit shadow-[4px_4px_0px_rgba(250,200,72,1)]'>
                              <p className=' font-semibold text-lg'>
                                Learning Objective
                              </p>
                            </div>

                            <div className='border-t-2 border-primary w-12'></div>
                            <div className='space-y-4'>
                              {props.objectives.map((objective, index) => {
                                return (
                                  <p
                                    key={index}
                                    className='text-[#767676] text-sm'
                                  >
                                    {objective}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                          <div className='space-y-2 mt-4 lg:basis-[30%]'>
                            <div className='border border-black px-2 py-1 w-fit shadow-[4px_4px_0px_rgba(250,200,72,1)]'>
                              <p className=' font-semibold text-lg'>
                                Business Objective
                              </p>
                            </div>

                            <div className='border-t-2 border-primary w-12'></div>
                            <p className='text-[#767676] text-sm'>
                              {props.businessObjective}
                            </p>
                          </div>
                          <div className='space-y-2 mt-4 lg:basis-[30%]'>
                            <div className='border border-black px-2 py-1 w-fit shadow-[4px_4px_0px_rgba(250,200,72,1)]'>
                              <p className=' font-semibold text-lg'>Skills</p>
                            </div>
                            <div className='border-t-2 border-primary w-12'></div>
                            <div className='space-y-4'>
                              {props.skills.map((skill, index) => {
                                return (
                                  <p
                                    key={index}
                                    className='text-[#767676] text-sm'
                                  >
                                    {skill}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div> */}
                        <div className='mt-4'>{props.customButton}</div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Card;
