import React from 'react';
// import { FaRegStar } from 'react-icons/fa';
const BenefitPoints = () => {
  return (
    <div>
      {/* <div className='mx-auto w-3/4 md:w-1/2'></div> */}
      <div className='grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2 gap-6 lg:gap-10 my-10  '>
        <div className='text-center space-y-2'>
          <svg
            className='h-8 mx-auto -ml-8'
            xmlns='http://www.w3.org/2000/svg'
            id='Layer_1'
            data-name='Layer 1'
            viewBox='0 0 24 24'
            width='512'
            height='512'
          >
            <path d='m11.851,10.356l-3.362,3.305c-.226.226-.523.339-.821.339s-.598-.114-.826-.342l-1.7-1.741c-.193-.197-.189-.514.009-.707.198-.192.515-.189.707.009l1.695,1.736c.051.05.176.051.231-.004l3.365-3.308c.197-.194.513-.191.707.006.194.197.191.514-.006.707Zm5.649,7.644h-7c-.276,0-.5.224-.5.5s.224.5.5.5h7c.276,0,.5-.224.5-.5s-.224-.5-.5-.5Zm0-13h-7c-.276,0-.5.224-.5.5s.224.5.5.5h7c.276,0,.5-.224.5-.5s-.224-.5-.5-.5Zm0,6.5h-4c-.276,0-.5.224-.5.5s.224.5.5.5h4c.276,0,.5-.224.5-.5s-.224-.5-.5-.5Zm-10.5,6c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm0-11c.552,0,1-.448,1-1s-.448-1-1-1-1,.448-1,1,.448,1,1,1Zm15-2v15c0,2.481-2.019,4.5-4.5,4.5H6.5c-2.481,0-4.5-2.019-4.5-4.5V4.5C2,2.019,4.019,0,6.5,0h11c2.481,0,4.5,2.019,4.5,4.5Zm-1,0c0-1.93-1.57-3.5-3.5-3.5H6.5c-1.93,0-3.5,1.57-3.5,3.5v15c0,1.93,1.57,3.5,3.5,3.5h11c1.93,0,3.5-1.57,3.5-3.5V4.5Z' />
          </svg>

          {/* <FaRegStar className='text-4xl mx-auto text-primary' /> */}
          <p className='font-bold text-xl text-clip'>Real-World Projects</p>
          <p>
            Work on projects sourced from companies and gain hands-on experience
          </p>
        </div>
        <div className='text-center space-y-2'>
          <svg
            className='h-8 mx-auto'
            xmlns='http://www.w3.org/2000/svg'
            id='Layer_1'
            data-name='Layer 1'
            viewBox='0 0 24 24'
          >
            <path d='m12,10c-1.654,0-3,1.346-3,3s1.346,3,3,3,3-1.346,3-3-1.346-3-3-3Zm0,5c-1.103,0-2-.897-2-2s.897-2,2-2,2,.897,2,2-.897,2-2,2Zm8.096-9.611l-3.484-3.485c-1.229-1.228-2.861-1.904-4.597-1.904h-5.515C4.019,0,2,2.019,2,4.5v15c0,2.481,2.019,4.5,4.5,4.5h11c2.481,0,4.5-2.019,4.5-4.5v-9.515c0-1.737-.677-3.369-1.904-4.596Zm-.707.707c.55.55.959,1.199,1.232,1.904h-5.121c-.827,0-1.5-.673-1.5-1.5V1.38c.704.273,1.354.682,1.904,1.231l3.484,3.485Zm-11.389,16.904c0-2.206,1.794-4,4-4s4,1.794,4,4h-8Zm13-3.5c0,1.93-1.57,3.5-3.5,3.5h-.5c0-2.757-2.243-5-5-5s-5,2.243-5,5h-.5c-1.93,0-3.5-1.57-3.5-3.5V4.5c0-1.93,1.57-3.5,3.5-3.5h5.515c.335,0,.663.038.985.096v5.404c0,1.378,1.121,2.5,2.5,2.5h5.404c.058.323.096.651.096.985v9.515Z' />
          </svg>

          <p className='font-bold text-xl text-clip'>Work Portfolio</p>
          <p>
            Demonstrate your skills and build a portfolio to impress future
            employers{' '}
          </p>
        </div>
        <div className='text-center space-y-2'>
          <svg
            className='h-8 mx-auto -ml-8'
            xmlns='http://www.w3.org/2000/svg'
            id='Layer_1'
            data-name='Layer 1'
            viewBox='0 0 24 24'
            width='512'
            height='512'
          >
            <path d='M12,24C5.38,24,0,18.62,0,12S5.38,0,12,0s12,5.38,12,12-5.38,12-12,12ZM12,1C5.93,1,1,5.93,1,12s4.93,11,11,11,11-4.93,11-11S18.07,1,12,1Zm-3.26,16c-.21,0-.42-.07-.6-.2-.34-.25-.48-.7-.35-1.1l.94-3.01-2.37-1.93c-.33-.28-.44-.72-.3-1.11,.14-.39,.52-.65,.94-.65h3l1.06-2.85c.14-.38,.51-.62,.94-.62s.8,.24,.94,.62l1.06,2.85h3c.42,0,.8,.26,.94,.66h0c.14,.39,.03,.84-.29,1.1l-2.37,1.93,.98,2.98c.13,.4,0,.85-.34,1.1-.34,.26-.8,.28-1.15,.05l-2.75-1.79-2.71,1.81c-.17,.11-.37,.17-.56,.17Zm-1.74-7.01l2.62,2.13c.16,.13,.22,.34,.16,.54l-1.04,3.34,2.98-1.99c.17-.11,.38-.11,.55,0l3.03,1.97-1.09-3.31c-.07-.2,0-.41,.16-.54l2.62-2.13h-3.35c-.21,0-.4-.13-.47-.33l-1.18-3.18-1.18,3.18c-.07,.2-.26,.33-.47,.33h-3.35Zm10,0h0Zm0,0h0s0,0,0,0Z' />
          </svg>

          <p className='font-bold text-xl text-clip'>Earn Rewards</p>
          <p>
            Get recognized for your efforts with monetary rewards, internships,
            and more{' '}
          </p>
        </div>
        <div className='text-center space-y-2'>
          <svg
            className='h-8 mx-auto -ml-12'
            xmlns='http://www.w3.org/2000/svg'
            id='Layer_1'
            data-name='Layer 1'
            viewBox='0 0 24 24'
            width='512'
            height='512'
          >
            <path d='M2.5,23H24v1H2.5c-1.378,0-2.5-1.122-2.5-2.5V0H1V21.5c0,.827,.673,1.5,1.5,1.5ZM17,5v1h5.293l-7.293,7.293-4-4-6.854,6.854,.707,.707,6.146-6.146,4,4L23,6.707v5.293h1V5h-7Z' />
          </svg>

          <p className='font-bold text-xl text-clip'>
            Professional Development
          </p>
          <p>Enhance your skills and boost your career prospects </p>
        </div>
        <div className='text-center space-y-2'>
          <img
            className='h-8 mx-auto'
            src='https://res.cloudinary.com/belong/image/upload/v1699617531/uploaded_resources/graduation_4339229_fgqsgk.png'
            alt='logo'
          />
          <p className='font-bold text-xl text-clip'>Experiential Learning</p>
          <p>
            Gain insights into industry challenges and network with experts{' '}
          </p>
        </div>
        <div className='text-center space-y-2'>
          <img
            className='h-8 mx-auto'
            src='https://res.cloudinary.com/belong/image/upload/v1699617664/uploaded_resources/focus_7195079_db7cvl.png'
            alt='logo'
          />
          <p className='font-bold text-xl text-clip'>Student-Centric</p>
          <p>
            Explore projects across domains and industries, for an enriching
            experience{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BenefitPoints;
